import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CustomButton from "../../../UI/button/button";
import CustomTextfield from "../../../UI/textfield/textfield";

import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_kyp_companies,
  gql_get_vendor_basic_details,
  gql_set_kyp_id,
} from "../../../../redux/actions/vendorActions/vendorAction";

import { SET_KYP_COMPANIES } from "../../../../constants/brmConstants";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { vendorCountries } from "../../../../utils/riskUtils/riskUtils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },
  dialogTitle: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  dialogTitle1: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  typoCont: { width: "100%" },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },

  fontSize: {
    fontSize: "14px",
  },
  fontBold: {
    fontWeight: 600,
  },

  companyBox: {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "10px",
    marginBottom: "15px",
    borderRadius: "10px",
    cursor: "pointer",
  },

  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // cursor: "pointer",
  },
}));

// const companies = [
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "e3fbae36-103a-4254-980f-f53338515ac9",
//     added: "2024-04-12T17:08:08.135Z",
//     addedBy: "u_36a3cfbe-8e6d-477b-beee-3af3d5dc7207",
//     companyRisk: 29.542976208929606,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE (UK) LIMITED",
//     openCases: 0,
//     status: "active",
//   },
//   {
//     _id: "3488d828-a36e-493a-886a-b349157856d8",
//     added: "2024-04-22T11:41:41.781Z",
//     addedBy: "u_40cc27bb-1ea1-4f51-982b-891f4a80fd15",
//     companyRisk: 40.98038949028454,
//     country: "GB",
//     labels: null,
//     name: "ACCENTURE",
//     openCases: 0,
//     status: "active",
//   },
// ];

function VendorKYP({ popup, setPopup }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { kypCompanies, vendorBasicDetails } = useSelector(
    (state) => state.brmData
  );
  const storedVendorId = localStorage.getItem("vendorId");

  const [vendorKypInputs, setVendorKypInputs] = useState({});
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);

  const handleVendorIqInputs = (event, newValue) => {
    const { name, value } = event.target;

    setVendorKypInputs((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSearch = () => {
    Object?.keys(vendorKypInputs)?.forEach((key) => {
      if (vendorKypInputs[key] === "") {
        delete vendorKypInputs[key];
      }

      dispatch(gql_get_kyp_companies(vendorKypInputs)); // console.log(vendorIqInputs);
    });
  };

  const handleAddCompany = (company) => {
    setConfirmPopup(true);
    setSelectedCompanyData(company);
  };

  const handleResetSearchResults = () => {
    setVendorKypInputs(null);
    dispatch({
      type: SET_KYP_COMPANIES,
      payload: [],
    });
  };

  const handleConnect = async () => {
    await dispatch(
      gql_set_kyp_id(
        vendorBasicDetails?._id || storedVendorId,
        selectedCompanyData?.id,
        selectedCompanyData?.country,
        selectedCompanyData?.regNo
      )
    );

    dispatch(
      gql_get_vendor_basic_details(vendorBasicDetails?._id || storedVendorId)
    );

    dispatch({
      type: SET_KYP_COMPANIES,
      payload: [],
    });

    setConfirmPopup(false);
    setPopup(false);
    setSelectedCompanyData(null);
  };

  const confirmPopupModal = () => {
    return (
      <Dialog
        open={confirmPopup}
        onClose={() => {
          setConfirmPopup(false);
        }}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography style={{ width: "500px" }}>
            Are you sure you want to connect with{" "}
            <span className={classes.fontBold}>
              {selectedCompanyData?.name}
            </span>
            ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setConfirmPopup(false)} color="primary">
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleConnect}
            color="primary"
            autoFocus
            variant="contained"
          >
            Proceed
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Dialog
      open={popup}
      onClose={() => {
        setPopup(false);
        dispatch({
          type: SET_KYP_COMPANIES,
          payload: [],
        });
        setShowAdvanceSearch(false);
        setVendorKypInputs(null);
      }}
      fullWidth="true"
      maxWidth="lg"
    >
      <CloseIcon
        style={{
          position: "absolute",
          top: 15,
          right: 20,
          cursor: "pointer",
          color: "#333",
        }}
        onClick={() => setPopup(false)}
      />
      <DialogTitle>
        <Grid
          container
          justifyContent="center"
          className={classes.dialogTitle1}
        >
          <Grid item xs={5}>
            <Typography className={classes.dialogTitle}>
              Know Your Partner
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ marginBottom: "20px", overflowY: "unset" }}>
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="flex-start"
          // style={{}}
        >
          {true && (
            <>
              <Box style={{ marginBottom: "20px" }}>
                <CustomButton
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowAdvanceSearch((prev) => !prev)}
                >
                  Advance Search
                </CustomButton>
              </Box>
              <Box
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
                className={classes.typoCont}
              >
                <Box style={{ width: "100%" }}>
                  {/* <label>Company Name</label> */}
                  <CustomTextfield
                    label={<span>Company Name</span>}
                    name="name"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={handleVendorIqInputs}
                    helperText=""
                    value={vendorKypInputs?.name || ""}
                  />
                </Box>
                <Box style={{ width: "100%" }}>
                  {/* <label>Country</label> */}
                  <Autocomplete
                    name="country"
                    options={vendorCountries}
                    getOptionLabel={(option) => option.country_name || ""}
                    value={vendorKypInputs?.country}
                    onChange={(e, newValue) =>
                      setVendorKypInputs((prev) => ({
                        ...prev,
                        country: newValue?.country_code,
                      }))
                    }
                    // renderOption={(props, option) => (
                    //   <li {...props}>
                    //     <Avatar
                    //       alt={option.country_name}
                    //       src={option.country_flag}
                    //       style={{ marginRight: 8 }}
                    //     />
                    //     {option.country_name}
                    //   </li>
                    // )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Box>
                <Box style={{ width: "100%" }}>
                  {/* <label>Company No.</label> */}
                  <CustomTextfield
                    label={<span>Company No.</span>}
                    name="regno"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={handleVendorIqInputs}
                    helperText=""
                    value={vendorKypInputs?.regno || ""}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    disabled={
                      ((vendorKypInputs?.name === undefined ||
                        vendorKypInputs?.name === "" ||
                        vendorKypInputs?.country === undefined ||
                        vendorKypInputs?.country === "") &&
                        (vendorKypInputs?.regno !== undefined ||
                          vendorKypInputs?.regno !== "") &&
                        (vendorKypInputs?.regno === undefined ||
                          vendorKypInputs?.regno === "" ||
                          vendorKypInputs?.country === undefined ||
                          vendorKypInputs?.country === "") &&
                        (vendorKypInputs?.name !== undefined ||
                          vendorKypInputs?.name !== "")) ||
                      (vendorKypInputs?.regno !== undefined &&
                        vendorKypInputs?.regno !== "" &&
                        vendorKypInputs?.country !== undefined &&
                        vendorKypInputs?.country !== "" &&
                        vendorKypInputs?.name !== undefined &&
                        vendorKypInputs?.name !== "")
                    }
                  >
                    <SearchRoundedIcon />
                    Search
                  </CustomButton>
                </Box>
              </Box>
              {showAdvanceSearch && (
                <>
                  <Typography
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    Advance Search:
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "20px",
                    }}
                    className={classes.typoCont}
                  >
                    <Box style={{ width: "100%" }}>
                      {/* <label>Company No.</label> */}
                      <CustomTextfield
                        label="First Line of Address"
                        name="address"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={handleVendorIqInputs}
                        helperText=""
                        value={vendorKypInputs?.address || ""}
                      />
                    </Box>
                    <Box style={{ width: "100%" }}>
                      {/* <label>Company No.</label> */}
                      <CustomTextfield
                        label="City"
                        name="city"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={handleVendorIqInputs}
                        helperText=""
                        value={vendorKypInputs?.city || ""}
                      />
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "20px",
                    }}
                    className={classes.typoCont}
                  >
                    <Box style={{ width: "100%" }}>
                      {/* <label>Company No.</label> */}
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="select-label">Status</InputLabel>
                        <Select
                          labelWidth={50}
                          fullWidth
                          labelId="select-label"
                          name="status"
                          value={vendorKypInputs?.status || ""}
                          // defaultValue={data?.answer}
                          onChange={handleVendorIqInputs}
                          variant="outlined"
                          size="small"
                          // disabled={isFrom === "createVendor" ? false : !edit}
                          MenuProps={{ classes: { paper: classes.selectMenu } }}
                          style={{ height: "40px" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {[
                            { name: "Active", value: "active" },
                            { name: "Non Active", value: "nonActive" },
                          ].map((option, i) => (
                            <MenuItem
                              key={i}
                              // style={{ width: 800 }}
                              value={option?.value}
                              className={classes.menuItem}
                            >
                              {option?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box style={{ width: "100%" }}>
                      {/* <label>Company No.</label> */}
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="select-label">Type</InputLabel>
                        <Select
                          labelWidth={40}
                          fullWidth
                          name="type"
                          labelId="select-label"
                          value={vendorKypInputs?.type || ""}
                          // defaultValue={data?.answer}
                          onChange={handleVendorIqInputs}
                          variant="outlined"
                          size="small"
                          // disabled={isFrom === "createVendor" ? false : !edit}
                          MenuProps={{ classes: { paper: classes.selectMenu } }}
                          style={{ height: "40px" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {[
                            { name: "Ltd", value: "ltd" },
                            { name: "Non Ltd", value: "nonLtd" },
                          ].map((option, i) => (
                            <MenuItem
                              key={i}
                              // style={{ width: 800 }}
                              value={option?.value}
                              className={classes.menuItem}
                            >
                              {option?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box style={{ width: "100%" }}>
                      {/* <label>Company No.</label> */}
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="select-label">Office Type</InputLabel>
                        <Select
                          labelWidth={90}
                          fullWidth
                          labelId="select-label"
                          name="officeType"
                          value={vendorKypInputs?.officeType || ""}
                          // defaultValue={data?.answer}
                          onChange={handleVendorIqInputs}
                          variant="outlined"
                          size="small"
                          // disabled={isFrom === "createVendor" ? false : !edit}
                          MenuProps={{ classes: { paper: classes.selectMenu } }}
                          style={{ height: "40px" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {[
                            { name: "HeadOffice", value: "headOffice" },
                            { name: "Branch", value: "branch" },
                          ].map((option, i) => (
                            <MenuItem
                              key={i}
                              // style={{ width: 800 }}
                              value={option?.value}
                              className={classes.menuItem}
                            >
                              {option?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Grid>
      </DialogContent>

      {kypCompanies?.length > 0 && (
        <DialogContent style={{ borderTop: "2px solid rgba(0,0,0,0.1)" }}>
          <Box
            style={{
              // padding: "0 10px",
              margin: " 20px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              Search results:{" "}
              <span style={{ fontWeight: "600" }}>{kypCompanies?.length}</span>
            </Typography>
            <CustomButton
              variant="outlined"
              color="secondary"
              onClick={handleResetSearchResults}
            >
              Reset Search Results
            </CustomButton>
          </Box>
          <Grid
            style={{
              height: "500px",
              // overflow: "scroll",
              gap: "10px",
            }}
          >
            <TableContainer component="paper">
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left">Company Name</TableCell>
                    <TableCell align="left">Country</TableCell>

                    <TableCell align="left">Company Address</TableCell>
                    <TableCell align="left">Reg No.</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Status Description</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Add</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {kypCompanies?.map((item, index) => (
                    <TableRow
                      key={item?._id}
                      className={classes.tableRow}
                      // onClick={() => console.log(data.id)}
                    >
                      <TableCell align="left">{item?.name}</TableCell>
                      <TableCell align="left">{item?.country || "-"}</TableCell>
                      <TableCell align="left">
                        {item?.address?.simpleValue
                          ?.split(",")
                          .slice(0, 2)
                          ?.join(",") || "-"}
                      </TableCell>
                      <TableCell align="left">{item?.regNo || "-"}</TableCell>
                      <TableCell align="left">{item?.status || "-"}</TableCell>
                      <TableCell align="left">
                        {item?.statusDescription || "-"}
                      </TableCell>
                      <TableCell align="left">{item?.type || "-"}</TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddCompany(item)}
                      >
                        <AddRoundedIcon style={{ color: "#888" }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        <Grid container spacing={2} className={classes.grid7}>
          <Grid item xs={12} md={12} className={classes.grid3}>
            {/* {kypCompanies?.length === 0 && (
              <CustomButton
                color="primary"
                className={classes.CustomButton3}
                onClick={() => {
                  setPopup(false);
                  setVendorKypInputs({});
                }}
              >
                Cancel
              </CustomButton>
            )} */}

            {/* {kypCompanies?.length > 0 && (
              <CustomButton
                color="primary"
                className={classes.CustomButton3}
                onClick={() => {
                  dispatch({
                    type: SET_KYP_COMPANIES,
                    payload: [],
                  });
                  setSelectedCompanyData(null);
                  setVendorKypInputs({});
                }}
              >
                Back
              </CustomButton>
            )} */}
            {/* {kypCompanies?.length === 0 && (
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={handleSearch}
                disabled={
                  !vendorKypInputs?.name || vendorKypInputs?.name === ""
                }
              >
                Search
              </CustomButton>
            )} */}

            {/* {kypCompanies?.length > 0 && (
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => setConfirmPopup(true)}
                disabled={!selectedCompanyData}
              >
                Confirm
              </CustomButton>
            )} */}
          </Grid>
        </Grid>
      </DialogActions>
      {confirmPopup && confirmPopupModal()}
    </Dialog>
  );
}

export default VendorKYP;
