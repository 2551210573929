import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CustomButton from "../../../UI/button/button";
import CustomTextfield from "../../../UI/textfield/textfield";
import { set } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_search_vendor_iq_companies,
  gql_get_vendor_basic_details,
  gql_set_vendor_iq_id,
} from "../../../../redux/actions/vendorActions/vendorAction";
import { SET_VENDOR_IQ_SEARCH_COMPANIES } from "../../../../constants/brmConstants";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },
  dialogTitle: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  dialogTitle1: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  typoCont: { width: "100%" },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },

  fontSize: {
    fontSize: "14px",
  },
  fontBold: {
    fontWeight: 600,
  },

  companyBox: {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "10px",
    marginBottom: "15px",
    borderRadius: "10px",
    cursor: "pointer",
  },

  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // cursor: "pointer",
  },
}));

function VendorIQ({ popup, setPopup }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vendorIqSearchCompanies, vendorBasicDetails } = useSelector(
    (state) => state.brmData
  );
  const storedVendorId = localStorage.getItem("vendorId");

  const [vendorIqInputs, setVendorIqInputs] = useState({});
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);

  const handleVendorIqInputs = (event) => {
    const { name, value } = event.target;

    setVendorIqInputs((prev) => {
      if (name === "name" || name === "type") {
        return { ...prev, [name]: value };
      } else {
        const arr = value.split(",");
        return { ...prev, [name]: arr };
      }
    });
  };

  const handleResetSearchResults = () => {
    setVendorIqInputs(null);
    dispatch({
      type: SET_VENDOR_IQ_SEARCH_COMPANIES,
      payload: [],
    });
  };

  const handleSearch = () => {
    Object?.keys(vendorIqInputs).forEach((key) => {
      if (
        Array.isArray(vendorIqInputs[key]) &&
        vendorIqInputs[key].length === 1 &&
        vendorIqInputs[key][0] === ""
      ) {
        delete vendorIqInputs[key];
      }

      dispatch(gql_get_search_vendor_iq_companies(vendorIqInputs));
      // console.log(vendorIqInputs);
    });
  };

  const handleCompany = (company) => {
    setSelectedCompanyData(company);
    setConfirmPopup(true);
  };

  const handleConnect = async () => {
    await dispatch(
      gql_set_vendor_iq_id(
        vendorBasicDetails?._id || storedVendorId,
        selectedCompanyData?.id
      )
    );

    dispatch(
      gql_get_vendor_basic_details(vendorBasicDetails?._id || storedVendorId)
    );

    dispatch({
      type: SET_VENDOR_IQ_SEARCH_COMPANIES,
      payload: [],
    });

    setConfirmPopup(false);
    setPopup(false);
    setSelectedCompanyData(null);
  };

  const confirmPopupModal = () => {
    return (
      <Dialog open={confirmPopup} onClose={() => setConfirmPopup(false)}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography style={{ width: "500px" }}>
            Are you sure you want to connect with{" "}
            <span className={classes.fontBold}>
              {selectedCompanyData?.name}
            </span>
            ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setConfirmPopup(false)} color="primary">
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleConnect}
            color="primary"
            autoFocus
            variant="contained"
          >
            Proceed
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Dialog
      open={popup}
      onClose={() => {
        setPopup(false);
        dispatch({
          type: SET_VENDOR_IQ_SEARCH_COMPANIES,
          payload: [],
        });
      }}
      fullWidth="true"
      maxWidth="lg"
    >
      <CloseIcon
        style={{
          position: "absolute",
          top: 15,
          right: 20,
          cursor: "pointer",
          color: "#333",
        }}
        onClick={() => setPopup(false)}
      />
      <DialogTitle>
        <Grid
          container
          justifyContent="center"
          className={classes.dialogTitle1}
        >
          <Grid item xs={5} style={{ position: "relative" }}>
            <Typography className={classes.dialogTitle}>
              Connect With Vendor IQ
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ marginBottom: "20px", overflowY: "unset" }}>
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="flex-start"
          // style={{}}
        >
          {true && (
            <>
              <Box
                className={classes.typoCont}
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
              >
                <Box style={{ width: "100%" }}>
                  {/* <label>Name of the Company</label> */}
                  <CustomTextfield
                    label="Name"
                    name="name"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={vendorIqInputs?.name || ""}
                    onChange={handleVendorIqInputs}
                    helperText=""
                  />
                </Box>
                <Box style={{ width: "100%" }}>
                  {/* <label>Name of the Company</label> */}
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="select-label">Type</InputLabel>
                    <Select
                      labelWidth={50}
                      fullWidth
                      labelId="select-label"
                      name="type"
                      value={vendorIqInputs?.type || ""}
                      // defaultValue={data?.answer}
                      onChange={handleVendorIqInputs}
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{ classes: { paper: classes.selectMenu } }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {[
                        { name: "Regulated", value: "regulated" },
                        { name: "Corporate", value: "corporate" },
                      ].map((option, i) => (
                        <MenuItem
                          key={i}
                          // style={{ width: 800 }}
                          value={option?.value}
                          className={classes.menuItem}
                        >
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <CustomButton
                    color="primary"
                    variant="contained"
                    className={classes.CustomButton3}
                    onClick={handleSearch}
                    disabled={
                      !vendorIqInputs?.name || vendorIqInputs?.name === ""
                    }
                  >
                    Search
                  </CustomButton>
                </Box>
              </Box>

              <Typography variant="body2" style={{ marginBottom: " 10px" }}>
                To search with multiple "Pass code", "Area code", "Country", and
                "Region" - Please separate by commas*
              </Typography>
              <Box
                className={classes.typoCont}
                style={{ display: "flex", gap: "10px" }}
              >
                <Box style={{ width: "100%" }}>
                  {/* <label>
                    Post code (search with multiple post codes, separate with
                    comma)
                  </label> */}
                  <CustomTextfield
                    label="Post code"
                    name="postcode"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={vendorIqInputs?.postcode || ""}
                    onChange={handleVendorIqInputs}
                    helperText=""
                  />
                </Box>

                <Box style={{ width: "100%" }}>
                  {/* <label>
                    Area code (search with multiple area codes, separate with
                    comma)
                  </label> */}
                  <CustomTextfield
                    label="Area code"
                    name="areacode"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={vendorIqInputs?.areacode || ""}
                    onChange={handleVendorIqInputs}
                    helperText=""
                  />
                </Box>

                <Box style={{ width: "100%" }}>
                  {/* <label>
                    Region (search with multiple regions, separate with comma)
                  </label> */}
                  <CustomTextfield
                    label="Region"
                    name="region"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={vendorIqInputs?.region || ""}
                    onChange={handleVendorIqInputs}
                    helperText=""
                  />
                </Box>

                <Box style={{ width: "100%" }}>
                  {/* <label>
                    Country (search with multiple countries, separate with
                    comma)
                  </label> */}
                  <CustomTextfield
                    label="Country"
                    name="country"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={vendorIqInputs?.country || ""}
                    onChange={handleVendorIqInputs}
                    helperText=""
                  />
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </DialogContent>

      {vendorIqSearchCompanies?.length > 0 && (
        <DialogContent style={{ borderTop: "2px solid rgba(0,0,0,0.1)" }}>
          <Box
            style={{
              // padding: "0 10px",
              margin: " 20px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              Search results:{" "}
              <span style={{ fontWeight: "600" }}>
                {vendorIqSearchCompanies?.length}
              </span>
            </Typography>
            <CustomButton
              variant="outlined"
              color="secondary"
              onClick={handleResetSearchResults}
            >
              Reset Search Results
            </CustomButton>
          </Box>
          <Grid
            style={{
              height: "500px",
              // overflow: "scroll",
              gap: "10px",
            }}
          >
            <TableContainer component="paper">
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left">Company Name</TableCell>
                    <TableCell align="left">Country</TableCell>

                    {/* <TableCell align="left">Country Address</TableCell> */}
                    {/* <TableCell align="left">Reg No.</TableCell> */}
                    <TableCell align="left">Status</TableCell>
                    {/* <TableCell align="left">Status Description</TableCell> */}
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Add</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {vendorIqSearchCompanies?.map((item, index) => (
                    <TableRow
                      key={item?._id}
                      className={classes.tableRow}
                      // onClick={() => console.log(data.id)}
                    >
                      <TableCell align="left">{item?.name}</TableCell>
                      <TableCell align="left">{item?.country || "-"}</TableCell>
                      {/* <TableCell align="left">
                        {item?.address?.simpleValue
                          ?.split(",")
                          .slice(0, 2)
                          ?.join(",") || "-"}
                      </TableCell> */}
                      {/* <TableCell align="left">{item?.regNo || "-"}</TableCell> */}
                      <TableCell align="left">{item?.status || "-"}</TableCell>
                      {/* <TableCell align="left">
                        {item?.statusDescription || "-"}
                      </TableCell> */}
                      <TableCell align="left">{item?.type || "-"}</TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCompany(item)}
                      >
                        <AddRoundedIcon style={{ color: "#888" }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
      )}

      {confirmPopup && confirmPopupModal()}
    </Dialog>
  );
}

export default VendorIQ;
