import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_kyp_data,
  gql_get_vendor_iq_data,
} from "../../../redux/actions/vendorActions/vendorAction";
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import VendorIqAddresses from "./VendorIqAddresses";
import VendorIqAlerts from "./VendorIqAlerts";
import VendorIqCyber from "./VendorIqCyber";
import KeyFinancials from "./kyp/KeyFinancials";
import DimentionCharts from "../esgDeclaration/dimentionChart/dimentionCharts";
import CreditScores from "./kyp/CreditScores";
import CompanyComplianceRisk from "./kyp/CompanyComplianceRisk";
import DarkWeb from "./kyp/DarkWeb";
import CyberRisk from "./kyp/CyberRisk";
import NoData from "../NoData/NoData";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },

  fontSize: {
    fontSize: "16px",
    // display: "flex",
    // justifyContent: "space-between",
  },
  fontBold: {
    fontWeight: 600,
    // marginRight: "10px",
  },
}));

function KypTab() {
  const { vendorBasicDetails, kypData } = useSelector((state) => state.brmData);
  const storedVendorIqId = localStorage.getItem("vendoriq_id");

  console.log(kypData);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(gql_get_kyp_data(vendorBasicDetails?.kypId));
  }, []);

  const riskAnalysis =
    kypData?.risks &&
    Object?.entries(kypData?.risks)?.map(([key, value]) => value || 0);

  if (kypData?.status === false) return <NoData message={kypData?.message} />;

  // console.log(riskAnalysis);
  return (
    <Grid>
      {kypData?.companyDetails && (
        <>
          <BlueBackgroundCard heading={"Organization"} />
          <Paper style={{ padding: "10px", marginBottom: "20px" }}>
            <Typography
              style={{ fontSize: "20px", fontWeight: "600", color: "#3374B9" }}
            >
              {kypData?.companyDetails?.legalName}
            </Typography>

            <Grid style={{ marginTop: "10px" }}>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Regestration No.:</span>
                {"  "}
                {kypData?.companyDetails?.registrationNumber || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Trading Status:</span>
                {"  "}
                {kypData?.companyDetails?.tradingStatus || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}>
                  {" "}
                  Trading Status Description:
                </span>
                {"  "}
                {kypData?.companyDetails?.tradingStatusDescription || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Type:</span>
                {"  "}
                {kypData?.companyDetails?.companyType || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> VAT No.:</span>
                {"  "}
                {kypData?.companyDetails?.vatNumber || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Registration Date:</span>
                {"  "}
                {kypData?.companyDetails?.registrationDate?.split("T")[0] ||
                  "-"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Website:</span>
                {"  "}
                {kypData?.companyDetails?.websites[0] || "N/A"}
              </Typography>
              <br></br>

              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Main Address:</span>
                {"  "}
                {kypData?.companyDetails?.mainAddress || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Telephone No.:</span>
                {"  "}
                {kypData?.companyDetails?.telephone || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Trading Address:</span>
                {"  "}
                {kypData?.companyDetails?.tradingAddress || "N/A"}
              </Typography>
              <br></br>

              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Industry Code:</span>
                {"  "}
                {kypData?.companyDetails?.industryCode || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}>
                  Industry Code Description:
                </span>
                {"  "}
                {kypData?.companyDetails?.industryCodeDescription || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Principal Activity:</span>
                {"  "}
                {kypData?.companyDetails?.principalActivity || "N/A"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Accounts Filing Date:</span>
                {"  "}
                {kypData?.companyDetails?.accountsFilingDate?.split("T")[0] ||
                  "-"}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Trade Debtors:</span>
                {"  "}
                {kypData?.companyDetails?.tradeDebtors || "N/A"}
              </Typography>
            </Grid>
          </Paper>

          <BlueBackgroundCard heading={"Risk Analysis"} />
          {kypData?.risks && (
            <Box>
              <DimentionCharts
                scores={riskAnalysis}
                labels={[
                  "AML Risk",
                  "Company Risk",
                  "Credit Risk",
                  "Dark Web Risk",
                  "Legal Risk",
                  "Stakeholder Risk",
                ]}
              />
            </Box>
          )}
          {kypData?.keyFinancials?.totalAssets && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ width: "49%" }}>
                <BlueBackgroundCard heading={"Credit Scores"} />
                <CreditScores scores={kypData?.creditScore} classes={classes} />
              </Box>
              <Box style={{ width: "49%" }}>
                <BlueBackgroundCard heading={"Company Compliance Risks"} />
                <CompanyComplianceRisk
                  data={kypData?.companyComplianceRisks}
                  classes={classes}
                />
              </Box>
            </Box>
          )}

          {(kypData?.keyFinancials?.totalLiabilities?.length > 0 ||
            kypData?.keyFinancials?.totalAssets?.length > 0) && (
            <BlueBackgroundCard heading={"Key Financials"} />
          )}
          {kypData?.keyFinancials?.totalAssets && (
            <Box style={{ height: "300px" }}>
              <KeyFinancials
                data={kypData?.keyFinancials?.totalAssets}
                currency={kypData?.keyFinancials?.totalAssetsCurrency}
                x_text={""}
                y_text={`Assets in ${kypData?.keyFinancials?.totalAssetsCurrency} currency`}
                heading={"Total Assets"}
              />
            </Box>
          )}
          {kypData?.keyFinancials?.totalLiabilities && (
            <Box style={{ height: "300px" }}>
              <KeyFinancials
                data={kypData?.keyFinancials?.totalLiabilities}
                currency={kypData?.keyFinancials?.totalLiabilitiesCurrency}
                x_text={""}
                y_text={`Liabilities in ${kypData?.keyFinancials?.totalLiabilitiesCurrency} currency`}
                heading={"Total Liabilities"}
              />
            </Box>
          )}

          {kypData?.darkWeb?.length > 0 && (
            <BlueBackgroundCard heading={"Dark Web"} />
          )}

          {kypData?.darkWeb?.length > 0 && (
            <Box>
              <DarkWeb data={kypData?.darkWeb} />
            </Box>
          )}

          {kypData?.cyberRisks?.analysisDate && (
            <BlueBackgroundCard heading={"Cyber Risk"} />
          )}

          {kypData?.cyberRisks?.analysisDate && (
            <Box>
              <CyberRisk cyberRisks={kypData?.cyberRisks} />
            </Box>
          )}
        </>
      )}
    </Grid>
  );
}

export default KypTab;
