import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

export default function DarkWeb({ data }) {
  const classes = useStyle();

  return (
    <Box sx={{ margin: "10px 0px" }}>
      <TableContainer component="paper">
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <TableCell align="left">Asset</TableCell>
              <TableCell align="left">Last Breached</TableCell>
              {/* <TableCell align="left" width={200}>
                  Security
                </TableCell> */}
              <TableCell align="left">Details</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={data?.uuid}
                className={classes.tableRow}
                // onClick={() => console.log(data.id)}
              >
                <TableCell align="left">{item?.value}</TableCell>
                <TableCell align="left">
                  {item?.leaks[0]?.breach_date?.split("T")[0]}
                </TableCell>

                <TableCell align="left">View</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
