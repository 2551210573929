import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

function VendorIqAddresses({ classes, data }) {
  return (
    <>
      {data?.addresses?.data?.map((address) => (
        <Paper style={{ padding: "10px", marginBottom: "20px" }}>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Street:</span>
            {"  "}
            {`${address?.address_1}, ${address?.address_2}` || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Town:</span>
            {"  "}
            {address?.town || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Country:</span>
            {"  "}
            {address?.country || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Post code:</span>
            {"  "}
            {address?.postcode || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Email:</span>
            {"  "}
            {address?.email || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Website:</span>
            {"  "}
            {address?.website || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Type:</span>
            {"  "}
            {address?.type || "N/A"}
          </Typography>
        </Paper>
      ))}
    </>
  );
}

export default VendorIqAddresses;
