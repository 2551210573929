import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import PercentageDoughnutChart from "../../UI/percentageDoughnut.js/percentageDoughnutChart";
import VendorIqPercentageDonut from "./percentageDonut/VendorIqPercentageDonut";

function VendorIqCyber({ classes, data }) {
  // const nameFormat = (name) => {
  //   name.split("_").map((element, key) => {
  //     console.log(
  //       `${element[0].toUpperCase()}${element.slice(
  //         1
  //       )} ${element[0].toUpperCase()}${element.slice(1)}`
  //     );
  //     return `${element.toUpperCase()}${element.slice(1)}`;
  //   });
  // };
  return (
    <>
      {data?.cyber?.data?.map((cyber) => (
        <Paper style={{ padding: "10px", marginBottom: "20px" }}>
          <Grid container gap={20}>
            {cyber?.categories &&
              Object.entries(cyber?.categories)?.map(([key, value]) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                  }}
                >
                  <VendorIqPercentageDonut
                    color="#88bf71"
                    emptyColor="#EBF6EF"
                    percentage={Math.round(value?.score)}
                    customSize={200}
                    label={value?.priority}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#88bf71",
                        borderRadius: "100%",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      {key}
                    </Typography>
                  </div>
                </Grid>
              ))}
          </Grid>
          {/* <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Category:</span>
            {"  "}
            {alert?.category || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Type:</span>
            {"  "}
            {alert?.type || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Impact:</span>
            {"  "}
            {alert?.impact || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Description:</span>
            {"  "}
            {alert?.description || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Message:</span>
            {"  "}
            {alert?.message || "N/A"}
          </Typography> */}
        </Paper>
      ))}
    </>
  );
}

export default VendorIqCyber;
