import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

function VendorIqAlerts({ classes, data }) {
  return (
    <>
      {data?.alerts?.data?.map((alert) => (
        <Paper style={{ padding: "10px", marginBottom: "20px" }}>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Category:</span>
            {"  "}
            {alert?.category || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Type:</span>
            {"  "}
            {alert?.type || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Impact:</span>
            {"  "}
            {alert?.impact || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Description:</span>
            {"  "}
            {alert?.description || "N/A"}
          </Typography>
          <Typography className={classes.fontSize}>
            <span className={classes.fontBold}> Message:</span>
            {"  "}
            {alert?.message || "N/A"}
          </Typography>
        </Paper>
      ))}
    </>
  );
}

export default VendorIqAlerts;
